body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  line-height: 2;
  background-color: #f1f1f1;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.player {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  white-space: nowrap;

  .file-select {
    align-self: center;
    justify-self: center;
    margin-top: auto;
    margin-bottom: auto;
    border: dashed 2px #555;
    padding: 20px 30px;
    text-align: center;
  }

  .loading {
    align-self: center;
    border: solid 2px #aaa;
    padding: 10px 20px 15px 20px;
    margin: auto 0 auto 0;

    .substep {
      color: #777;
      font-size: 16px;
    }

    .progress {
      height: 4px;
      border: solid 1px #000;
      width: 300px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: stretch;

      .filled {
        background-color: #000;
        height: 4px;
        width: 0%;
      }
    }

    .error-content {
      color: red;
    }
  }

  video {
    width: 100%;
    height: calc(100vh - 20px);
  }

  .timeline {
    height: 20px;
    width: 100%;
  }
}

.drop-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(white, 0.5);
  z-index: 2;
  pointer-events: none;
}

.drop-indicator .inner {
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  border: dashed 6px skyblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #555;
  font-size: 5vw;
}
